<template>
  <div
    class="inline-flex items-center"
    :class="[proxyRounded, proxySize, proxyTheme]"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
const themeList = {
  secondary: 'bg-gray-100 text-gray-800',
  primary: 'bg-primary-100 text-primary-800',
  success: 'bg-green-100 text-green-800',
  destructive: 'bg-red-100 text-red-800',
  info: 'bg-blue-100 text-blue-800',
  none: '',
}

const sizeList = {
  sm: 'px-2.5 h-5 text-xs font-medium',
  md: 'px-3 h-6 text-xs font-medium',
  lg: 'px-3.5 h-7 font-medium',
  none: '',
}

const roundedList = {
  normal: 'rounded',
  sm: 'rounded-sm',
  md: 'rounded-md',
  lg: 'rounded-lg',
  full: 'rounded-full',
  none: '',
}

interface Props {
  size?: keyof typeof sizeList
  theme?: keyof typeof themeList
  rounded?: keyof typeof roundedList
}

const props = withDefaults(defineProps<Props>(), {
  rounded: 'full',
  theme: 'primary',
  size: 'md',
})

const proxyTheme = computed(() => props.theme && themeList[props.theme])
const proxySize = computed(() => props.size && sizeList[props.size])
const proxyRounded = computed(() => props.rounded && roundedList[props.rounded])
</script>
